import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import CatList from '../catList/CatList'

const EducationGridStyles = styled.div``

const EducationStyles = styled.div`
  h5 {
    margin: 0;
    font-style: italic;
    margin-bottom: 8px;
  }

  margin-bottom: 20px;
`

function SingleEducationItem({ education }) {
  return (
    <EducationStyles>
      <Link className="subtle" to={`/education/${education.slug.current}`}>
        <h2>{education.title}</h2>
        <h5>{education.headline}</h5>
      </Link>
      <CatList categories={education.categories} keyName={education.id} />
    </EducationStyles>
  )
}

export default function EducationList({ education }) {
  return (
    <EducationGridStyles>
      {education.map(edItem => (
        <SingleEducationItem key={edItem.id} education={edItem} />
      ))}
    </EducationGridStyles>
  )
}
