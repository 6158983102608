import { graphql } from 'gatsby'
import React from 'react'
import EducationList from '../components/lists/EducationList'
import CatNavList from '../templates/CategoryNavList'
import { filterForCat } from '../utils/utils'

export default function EducationPage({ data, location }) {
  const education = data.education.nodes
  const filteredEd = filterForCat(education, location)

  return (
    <div className="content-width">
      <h1 className="title">Pioneer Education</h1>
      {education.length ? (
        <>
          <CatNavList
            items={education}
            location={location}
            baseUrl="/education"
          />
          <EducationList education={filteredEd}>Education Page</EducationList>
        </>
      ) : (
        <h3
          style={{
            textAlign: 'center',
          }}
        >
          This section is under construction. Come back soon to learn about
          Pioneer Education.
        </h3>
      )}
    </div>
  )
}

export const query = graphql`
  query EducationQuery {
    education: allSanityEducation {
      nodes {
        id
        title
        headline
        categories {
          name
          invert
          color {
            hex
          }
        }
        slug {
          current
        }
      }
    }
  }
`
